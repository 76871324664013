export interface SubscriptionPlanFeature {
  name: string;
  included: boolean;
}

export interface TokenPackConfig {
  name: string;
  tokenAmount: number;
  price: number;
  priceId: string;
}

export interface SubscriptionPlanConfig {
  id: string;
  name: string;
  price: number;
  tokenAllowance: number;
  features: SubscriptionPlanFeature[];
}

export const SUBSCRIPTION_PLANS = {
  FREE: {
    id: 'price_free',
    name: 'Free',
    price: 0,
    tokenAllowance: 0,
    features: [
      { name: 'Blind Readings', included: true },
      { name: 'Daily Tarot Card Draw', included: true },
      { name: 'Monthly Tokens', included: false },
      { name: 'Question Readings', included: false },
    ],
  },
  BASIC: {
    id: process.env.STRIPE_BASIC_PRICE_ID!,
    name: 'Basic',
    price: 9.99,
    tokenAllowance: 100000,
    features: [
      { name: 'Blind Readings', included: true },
      { name: 'Daily Tarot Card Draw', included: true },
      { name: '100,000 Monthly Tokens', included: true },
      { name: 'Question Readings', included: true },
    ],
  },
  PRO: {
    id: process.env.STRIPE_PRO_PRICE_ID!,
    name: 'Pro',
    price: 19.99,
    tokenAllowance: 500000,
    features: [
      { name: 'Blind Readings', included: true },
      { name: 'Daily Tarot Card Draw', included: true },
      { name: '500,000 Monthly Tokens', included: true },
      { name: 'Question Readings', included: true },
    ],
  },
} as const satisfies Record<string, SubscriptionPlanConfig>;

export const TOKEN_PACKS = {
  SMALL: {
    name: 'Small Pack',
    tokenAmount: 10000, // 10,000 tokens
    price: 4.99,
    priceId: process.env.STRIPE_SMALL_TOKEN_PACK_PRICE_ID!,
  },
  MEDIUM: {
    name: 'Medium Pack',
    tokenAmount: 50000, // 50,000 tokens
    price: 9.99,
    priceId: process.env.STRIPE_MEDIUM_TOKEN_PACK_PRICE_ID!,
  },
  LARGE: {
    name: 'Large Pack',
    tokenAmount: 150000, // 150,000 tokens
    price: 19.99,
    priceId: process.env.STRIPE_LARGE_TOKEN_PACK_PRICE_ID!,
  },
} as const;

export type SubscriptionPlanId = keyof typeof SUBSCRIPTION_PLANS;
export type SubscriptionPlan = typeof SUBSCRIPTION_PLANS[SubscriptionPlanId];
export type TokenPackKey = keyof typeof TOKEN_PACKS;

export const getTokenAllowanceForPlan = (planId: SubscriptionPlanId): number => {
  return SUBSCRIPTION_PLANS[planId].tokenAllowance;
};

export const getPlanIdFromPrice = (priceId: string): SubscriptionPlanId | null => {
  const plan = Object.entries(SUBSCRIPTION_PLANS).find(([_, plan]) => plan.id === priceId);
  return plan ? (plan[0] as SubscriptionPlanId) : null;
};

export const getPlanFromPrice = (priceId: string): SubscriptionPlan | null => {
  const planId = getPlanIdFromPrice(priceId);
  return planId ? SUBSCRIPTION_PLANS[planId] : null;
};

export function getTokenPackFromPrice(priceId: string): TokenPackConfig | null {
  const pack = Object.values(TOKEN_PACKS).find(pack => pack.priceId === priceId);
  return pack || null;
};
